import React from 'react';

import {About, Footer, Header, Work, Skills} from './container';
import { NavBar } from './components';

import './App.scss';

const App = () => {
  return (
   <div className= "app">
    <NavBar/>
    <Header/>
    <About/>
    <Work/>
    <Skills/>
    <Footer/>
   
    
   </div>
  );
}

export default App;