import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
const SocialMedia = () => {
  return (
    <div className='app__social'>
      <div>
        <a href="https://www.linkedin.com/in/tolulope-adedoyin-a57b43220">
        <BsLinkedin/>
        </a>
      </div>
    </div>
  )
}

export default SocialMedia